import * as act from './actions';
import * as defaults from './defaults';

import Requests from "../api_requests/apiLook2buy";

const initialToken = () => {
    const tk = sessionStorage.getItem('token');
    if(tk !== undefined && tk !== null) return tk;
    return null;
}

const getVideoStyles = () => {
    let filters = localStorage.getItem('videoFilters');
    console.log("localstorage filters", filters);
    if(filters === undefined || filters === null){
        filters = defaults.videoFilter;
    }
    else filters = JSON.parse(filters);
    return filters;
}



const initialState = {
    auth: Requests.getUserInfo(),
    token: initialToken(),
    isAuthorized: false,
    inPairing: false,
    tokenCode: null,
    xInterval: false,
    ua: null,
    session: null,
    streamStats: null,
    networkInfoLoading: true,
    networkInfo: {
        download: {kbps: 0, rating:'0/0'},
        upload: {kbps: 0, rating:'0/0'},
    },
    videoFilters: getVideoStyles(),
    adminSchedules: [],
    scheduleId: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case act.SET_TOKEN:
            Requests.saveToken(action.payload);
            return {
                ...state,
                token: action.payload
            }
        case act.SET_AUTH:
            Requests.setUserInfo(action.payload);
            return {
                ...state,
                auth: action.payload
            }
        case act.LOGOUT:
            const x = {role: null,
                id: null, group_id: null};
            Requests.setUserInfo(x);
            Requests.saveToken(null);
            state.session.disconnect();
            return {...state, auth: x, token: null, session: null, ua: null}
        case act.SET_IN_PAIRING:
            return {...state, inPairing: action.payload}
        case act.SET_TOKEN_CODE:
            return {...state, tokenCode: action.payload}
        case act.SET_XINTERVAL:
            return {...state, xInterval: action.payload}
        case act.SET_UA:
            return {...state, ua: action.payload}
        case act.SET_SESSION:
            return {...state, session: action.payload}
        case act.SET_DEVICES:
            return {...state, devices: action.payload}
        case act.SET_NETWORK_INFO:
            return {...state, networkInfo: action.payload}
        case act.SET_STREAM_STATS:
            return {...state, streamStats: action.payload}
        case act.SET_VIDEO_FILTERS:
            localStorage.setItem('videoFilters', JSON.stringify(action.payload));
            return {...state, videoFilters: action.payload}
        case act.SET_IS_AUTHORIZED:
            return {...state, isAuthorized: action.payload}
        case act.SET_NETWORK_LOADING:
            return {...state, networkInfoLoading: action.payload}
        case act.SET_ADMIN_SCHEDULES:
            return {...state, adminSchedules: action.payload}
        case act.SET_SCHEDULE:
            return {...state, scheduleId: action.payload}
        default:
            return state
    }
}

export default reducer;
