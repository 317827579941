import React from "react";
import List from "@material-ui/core/List";
import RetailerItem from './RetailerItem';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const RetailerList = ({elements, callHandler}) => {
    const classes = useStyles();

    return (
        <List component="nav" className={classes.root}>
            {elements.map(item => <RetailerItem key={item.id} id={item.id} name={item.name} surname={item.surname} call={callHandler} />)}
        </List>
    )
}

export default RetailerList;