import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import * as defaults from '../../store/defaults';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "300px",
        padding: 10,
        height: "auto",
        backgroundColor: "#fafafa"
    },
    container: {
        width: '300px'
    }

}));

const VideoFilter = ({filters, setFilters}) => {
    const classes = useStyles();
    const [flts, setFlts] = useState(filters);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    }

    // salvo il valore interno al change
    const handleChange = (e, newValue) => {
        if(e !== ''){
            let newFilter = {...flts}
            newFilter[e] = newValue
            setFlts(newFilter)
        }
    }

    //salvo il valore al click up del mouse
    const handleChangeCommitted = (e, newValue) => {
        setFilters(flts);
    }

    const openPopover = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const resetFilters = () => {
        setFlts(defaults.videoFilter);
        setFilters(defaults.videoFilter);
    }

    function valuetext(value) {
        return `${value/2}%`;
    }
    const open = Boolean(anchorEl);
    return(
        <>
            <Button style={{backgroundColor: '#fff', color: '#000'}} variant="contained" onClick={openPopover}>OPEN FILTER</Button>
            <Popover
                id="filters_popover"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                open={open}
            >
                <div className={classes.root}>
                    <Typography gutterBottom>Luminosità</Typography>
                    <Slider
                        getAriaValueText={valuetext}
                        aria-labelledby="continuous-slider"
                        valueLabelDisplay="auto"
                        value={flts.brightness}
                        step={10}
                        max={200}
                        onChangeCommitted={handleChangeCommitted}
                        onChange={(e, val) => handleChange('brightness', val)}
                        id="slider_brightness"
                    />
                    <Typography gutterBottom>Contrasto</Typography>
                    <Slider
                        getAriaValueText={valuetext}
                        aria-labelledby="continuous-slider"
                        valueLabelDisplay="auto"
                        value={flts.contrast}
                        step={10}
                        max={200}
                        onChangeCommitted={handleChangeCommitted}
                        onChange={(e, val) => handleChange('contrast', val)}
                        id="slider_contrast"
                    />
                    <Typography gutterBottom>Saturazione</Typography>
                    <Slider
                        getAriaValueText={valuetext}
                        aria-labelledby="continuous-slider"
                        valueLabelDisplay="auto"
                        value={flts.saturation}
                        step={10}
                        max={200}
                        onChangeCommitted={handleChangeCommitted}
                        onChange={(e, val) => handleChange('saturation', val)}
                        id="slider_saturation"
                    />
                    <Button color="primary" variant="outlined" onClick={resetFilters}>RESET</Button>
                </div>
            </Popover>
        </>
    )
}

export default VideoFilter;
