import _ from 'lodash';

export const getStreamInfo = (stats) => {
    console.log(stats);
    return {
        "ar_packetLost": _.get(stats, ['audioReceived', 'packetsLost'],0),
        "ar_jitter": _.get(stats, ['audioReceived', 'jitter'],0),
        "ar_bitsReceivedPerSecond": _.get(stats, ['audioReceived', 'bitsReceivedPerSecond'],0),
        "vr_packetLost": _.get(stats, ['videoReceived', 'packetsLost'],0),
        "vr_resolution": _.get(stats, ['videoReceived', 'width'],0)+'/'+_.get(stats, ['videoReceived', 'height'],0),
        "vr_bitsReceivedPerSecond": _.get(stats, ['videoReceived', 'bitsReceivedPerSecond'],0),
        "vr_framePerSecond": _.get(stats, ["videoReceived","framesDecodedPerSecond"],0),
        "as_bitsSentPerSecond": _.get(stats, ['audioSent', 'bitsSentPerSecond'],0),
    }
}