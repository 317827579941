import React, {useEffect, useRef, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import CallEndIcon from '@material-ui/icons/CallEnd';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import VideoFilter from "../videoFilters/VideoFilter";
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import MicToggle from "../MicToggle";
import Statistics from "./Statistics";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#e41b13'
    },
    title: {
        marginLeft: theme.spacing(2),
    },
    videoStream: {
        width: "auto",
        minWidth: "1280px",
        maxWidth: "1440px",
        height: "auto",
        minHeight: "720px",
        maxHeight: "900px",
        // filter: props => `brightness(${props.filters.brightness})`
        filter: props => `brightness(${props.filters.brightness}%) contrast(${props.filters.contrast}%) saturate(${props.filters.saturation}%)`
    },
    hangup: {
        color: '#ffffff',
        // backgroundColor: '#808080'

    },
    hangupIcon: {
        marginRight: 5,
        color: "#ef2922",
        fontSize: 50
    },
    content: {
        width: "100%",
        backgroundColor: '#2f2d2d',
        margin: 0,
        padding: 0,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        minHeight: 500
    },
    stats: {
        color: "#bfbfbf",
        position: "fixed",
        top: "10%",
        left: 0,
        zIndex: 10,
        width: "20%",
        display: "flex",
        flexDirection: "column"
    },
    muteIcon: {
        // position: 'fixed',
        // bottom: 50,
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // zIndex: 20
        // marginLeft: 20,
        // marginRight: 10
    },
    mainDiv: {
        position: 'relative',
        minHeight: 500,
        // backgroundColor: '#00ff00'
    },
    bottomButton: {
        display: 'flex',
        flexDirection: 'row',
        // width: 200,
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        bottom: 20,
        justifyContent: 'center'
    },
    localVideoStream: {
        maxHeight: '140px',
        // backgroundColor: '#0000ff',
        position: 'absolute',
        left: 10,
        bottom: 10
    }
}));

const useSpacerStyles = makeStyles(() => ({
    spacer: {
        flex: 1
    }
}))

const bitsToBytes = (bits) => {
    return bits/8;
}


const Spacer = () => {
    const classes = useSpacerStyles();
    return (
        <div className={classes.spacer}>
        </div>)
}

const VideoStream = ({src, localStream, show, hangupCall, muted, id, videoFilters, setVideoFilters}) => {

    const classes = useStyles({filters: videoFilters});
    const remoteVideoRef = useRef();
    const localVideoRef = useRef();
    let [onOpen, setOnOpen] = useState(false);

    useEffect( () => {
        if(src && remoteVideoRef.current !== undefined && remoteVideoRef.current !== null) {
            setStream(remoteVideoRef.current, src.getData());
        }
        if(localStream && localVideoRef.current !== undefined && localVideoRef.current !== null) {
            setStream(localVideoRef.current, localStream.getData());
        }
    },[src, show, onOpen, localStream]);


    const setStream = (element, stream) => {
        if(void 0 === element) return;
        if(void 0 !== element.srcObject) element.srcObject = stream;
        else {
            if(void 0 !== element.mozSrcObject) element.mozSrcObject = stream;
            else{
                if(void 0 !== element.src){
                    try {
                        // eslint-disable-next-line no-undef
                        element.src = webkitURL.createObjectURL(stream);
                    }
                    catch (e) {
                        element.src = URL.createObjectURL(stream);
                    }
                }
                else {
                    console.error("Video element is null");
                    return false;
                }
            }
        }
    }

    const handleMute = () => {
        console.log('Handle mute audio')
        localStream.muteAudio()
    }

    const handleUnmute = () => {
        console.log('handle unmute audio')
        localStream.unmuteAudio()
    }
    return(
        <div>
            {/*onEntered è una funzione chiamata all'apertura del dialog. Cambiando lo stato di onOpen viene avviato
            useEffect e caricato il link nel tag video */}
            <Dialog fullScreen open={show} onEntered={()=>setOnOpen(!onOpen)}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.title}>
                            VideoCall
                        </Typography>
                        <Spacer />
                        <Statistics />
                        <VideoFilter filters={videoFilters} setFilters={setVideoFilters}/>

                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.content}>
                    <div className={classes.mainDiv}>
                        <video
                            id={id}
                            className={classes.videoStream}
                            ref={remoteVideoRef}
                            muted={muted}
                            autoPlay={true}
                        />
                        <video
                            id={id}
                            className={classes.localVideoStream}
                            ref={localVideoRef}
                            muted={true}
                            autoPlay={true}
                        />
                        <div className={classes.bottomButton}>
                            <MicToggle className={classes.muteIcon} onMicEnable={handleUnmute} onMicDisable={handleMute}/>
                            <IconButton
                                className={classes.hangup}
                                edge="end"
                                onClick={hangupCall}
                            >
                                <CallEndIcon className={classes.hangupIcon}/>
                            </IconButton>
                        </div>
                    </div>



                </DialogContent>
            </Dialog>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        videoFilters: state.videoFilters,
        stats: state.streamStats
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setVideoFilters: (flts) => dispatch({type: 'SET_VIDEO_FILTERS', payload:flts}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoStream);