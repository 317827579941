import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import List from "@material-ui/core/List";
import {connect} from "react-redux";
import * as acts from '../../store/actions';
import Requests from "../../api_requests/apiLook2buy";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import moment from "moment";
import StatusBar from "../../components/statusbar/StatusBar";

const ScheduleCard = ({data, onDelete}) => {
    const startAt = moment(data.startAt).format("YYYY-MM-DD HH:mm:ss")

    return (
        <Card>
            <CardContent style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: data.used ? '#ff7272' : '#66bb6a',
                justifyContent: 'space-between',
                padding: 4
            }}>
                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <Typography variant='subtitle2'>{data.id}</Typography>
                    <Typography variant='body2'>Descrizione: {data.description}</Typography>
                    <Typography variant='body2'>Start: {startAt}</Typography>
                    <Typography variant='body2'>Durata: {data.duration}</Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <IconButton onClick={() => onDelete(data.id)}>
                        <DeleteIcon />
                    </IconButton>
                </div>

            </CardContent>
        </Card>
    )
}


const AddScheduleDialog = ({open, onClose, onSave}) => {
    const initialForm = {
        description: '',
        startAt: '2020-01-01T00:00',
        duration: '01:00'
    }
    const [form, setForm] = useState(initialForm)

    const handleChange = (e) => {
        const id = e.target.id
        let value = e.target.value
        setForm(prevState => {
            prevState[id] = value
            return {...prevState}
        })
    }

    const handleSave = () => {
        let temp = {...form}
        temp['startAt'] = moment(form['startAt']).format()
        onSave(temp)
    }

    const handleCancel = () => {
        onSave({...form})
        setForm(initialForm)
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="title">Aggiungi nuova schedule</DialogTitle>
            <DialogContent>
                <div style={{display: 'flex', flexDirection:'column'}}>
                    <TextField
                        id="description"
                        label='Descrizione'
                        type="text"
                        value={form.description}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="startAt"
                        label='Data inizio'
                        type="datetime-local"
                        value={form.startAt}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="duration"
                        label='Durata'
                        type="time"
                        value={form.duration}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>
                    Cancel
                </Button>
                <Button onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const AdminPage = ({adminSchedules, setAdminSchedules}) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const getSchedules = () => {
        Requests.getAllSchedules()
            .then(res => {
                console.log(res)
                setAdminSchedules(res)
            })
    }
    useEffect(() => {
        getSchedules()
    }, [])

    const handleDeleteSchedule = (id) => {
        setAdminSchedules(adminSchedules.filter(as => as.id !== id))
        Requests.deleteOneSchedule(id)
            .then(res => {
                // getSchedules();
                console.log("Schedule eliminata")
            })
            .catch(err => console.error(err))
    }

    const handleAddScheduleOpen = () => {
        setDialogOpen(true)
    }

    const handleScheduleDialogClose = () => {
        setDialogOpen(false)
    }
    const handleAddSchedule = (data) => {
        Requests.addOneSchedule(data)
            .then(() => {
                getSchedules()
                setDialogOpen(false)
            })
            .catch(error => console.error(error))
    }

    return (
        <>
            <StatusBar />
            <AddScheduleDialog open={dialogOpen} onClose={handleScheduleDialogClose} onSave={handleAddSchedule}/>
            <div style={{paddingLeft: 4}}>
                <Typography variant="h6">Schedules</Typography>
                <Button
                variant="contained"
                startIcon={<AddCircleIcon/>}
                onClick={handleAddScheduleOpen}
                >
                    Nuova schedule
                </Button>
                <List style={{width: 400}}>
                    {adminSchedules ? adminSchedules.map(schedule => <ScheduleCard
                        key={schedule.id}
                        data={schedule}
                        onDelete={handleDeleteSchedule}
                    />) : null}
                </List>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        adminSchedules: state.adminSchedules
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setAdminSchedules: (sc) => dispatch({type: acts.SET_ADMIN_SCHEDULES, payload: sc}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);