import React, {useState, useEffect, useCallback} from 'react'
import Requests from '../../api_requests/apiLook2buy';
import {connect} from 'react-redux'
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import moment from "moment";


const useStyles = makeStyles({
    root: {
        // backgroundColor: '#5e76ff',
        width: "100%",
        margin: 0,
        padding: 0,
        justifyContent:"center",
        alignItems:"center",
    },
    card: {
        width: "20%",
        border: "6px solid #e41b13",
        display:"flex",
        marginTop: "10%",
        marginLeft: "auto",
        marginRight: "auto",
        padding:0,

    },
    '@media (max-width: 800px)':{
        card: {
            width: "100%",
            marginLeft: 0,
            marginRight: 0,
            borderRightStyle: 'none',
            borderLeftStyle: 'none'
        }
    },
    field: {
        marginBottom: '10px'
    },
    button: {
        backgroundColor: '#e41b13',
        color: '#ffffff'
    },
    errorBox:{
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '20%',
        justifyContent: 'center',
        border: '4px solid #e41b13',
        marginTop: 50,
        padding: 10,
        borderRadius: 2
    }
});

const LoginForm = ({classes, sendLogin, setUserEmail, setUserPassword, setShowCodeLogin}) => {
    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            sendLogin()
        }
    }

    //controllare se l'utente è già loggato
    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent>
                    <TextField
                        fullWidth
                        className={classes.field}
                        label="Email"
                        type="email"
                        onChange={e => setUserEmail(e.target.value)}
                    />

                    <TextField
                        fullWidth
                        className={classes.field}
                        label="Password"
                        type="password"
                        onChange={e => setUserPassword(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', flexDirection: 'row', marginTop: 10, marginBottom: 10}}>
                        <Button variant="contained" className={classes.button} onClick={sendLogin}>LOGIN</Button>
                    </div>
                    <Typography variant="caption" style={{cursor: 'pointer'}} onClick={() => setShowCodeLogin(true)}>Hai già un codice? clicca qui.</Typography>
                </CardContent>
            </Card>
        </div>
    )
}

const ScheduleIdForm = ({classes, sendCodeLogin, setShowCodeLogin}) => {
    const [code, setCode] = useState('');
    const handleCodeChange = (e) => {
        const val = e.target.value;
        setCode(val);
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            sendCodeLogin(code)
        }
    }

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent style={{width: '100%'}}>
                    <TextField
                        fullWidth
                        type="text"
                        label="Codice"
                        value={code}
                        className={classes.field}
                        onChange={handleCodeChange}
                        onKeyPress={handleKeyPress}
                    />
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', flexDirection: 'row', marginTop: 10, marginBottom: 10}}>
                        <Button variant="contained" className={classes.button} onClick={() => sendCodeLogin(code)}>LOGIN</Button>
                    </div>
                    <Typography variant="caption" style={{cursor: 'pointer'}} onClick={() => setShowCodeLogin(false)}>Torna al login.</Typography>
                </CardContent>
            </Card>
        </div>
    )
}

const LoginPage = ({setUA, setSession, ua, session, auth, setAuth, token, setToken, history, setSchedule}) => {

    const classes = useStyles();
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [newError, setNewError] = useState({
        show: false,
        msg: ''
    })
    const [showCodeLogin, setShowCodeLogin] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const setError = (msg) => {
        if(msg === false){
            setNewError({show: false, msg: ''});
        }
        else {
            setNewError({show: true, msg: msg});
        }
    }
    const sendLogin = () => {
        Requests.login({
            "email": userEmail,
            "password": userPassword,
        }, (res) => {
            if(res.code === 200){
                setToken(res.data.token);
                setAuth({
                    id: res.data.id,
                    role: res.data.role,
                    group_id: res.data.group_id,
                    apiKey: res.data.apiKey
                });
                if(res.data.role === 1){
                    history.push('/guest');
                }
                else if(res.data.role === 2){
                    history.push('/retailer');
                }
                else if(res.data.role === 999){
                    history.push('/admin')
                }

            }
            else console.log("Errore durante login", res.data);
        })
    }



    const sendCodeLogin = (code) => {
        console.log("send code login "+code)
        //check code validity
        Requests.getOneSchedule(code)
            .then(res => {
                setSchedule(res)
                // const today = new Date().toISOString().split('T')[0]
                const now = moment();
                const startAt = moment(res.startAt)
                const endAt = moment(res.startAt).add(res.duration);
                if(now < startAt) {
                    // accesso prima della validità del codice
                    setError('Lo slot non è ancora disponibile')
                }
                else if(now > endAt) {
                    setError('Lo slot non è più disponibile')
                }
                else{
                    doDummyLogin(1, 1) // hard coded userId e groupId

                }
            })
            .catch(error => {
                if(error.status === 404){
                    setLoaded(true);
                    setError("Codice slot non valido");
                }
            })
    }

    const doDummyLogin = useCallback((user_id, group_id) => {
        Requests.dummyLogin(parseInt(user_id), parseInt(group_id), (res => {
            console.log("res: ",res);
            if(res.code === 200) {
                setToken(res.data.token);
                setAuth({
                    id: res.data.id,
                    role: res.data.role,
                    group_id: res.data.group_id,
                    apiKey: res.data.apiKey
                });
                if(res.data.role === 1){
                    history.push('/guest');
                }
                else{
                    history.push('/retailer');
                }
            }
        }));
    }, [])

    useEffect(() => {
        const queryParam = window.location.search;
        let params = new URLSearchParams(queryParam);
        if(params.has('code')){
            sendCodeLogin(params.get('code'))
        }
        else if(params.has('user_id') && params.has('group_id')){
            doDummyLogin(params.get('user_id'),params.get('group_id'))
        }
        else{
            setLoaded(true)
        }
    }, [])



    return (
        <>
            {newError.show ?
                <div className={classes.errorBox}>
                    <Typography variant={"subtitle2"} style={{color: '#e41b13', textTransform:'uppercase'}}>{newError.msg}</Typography>
                </div> : null}
            {loaded ? <div>
                { showCodeLogin ?
                    <ScheduleIdForm classes={classes} sendCodeLogin={sendCodeLogin} setShowCodeLogin={setShowCodeLogin}/>
                    :
                    <LoginForm classes={classes} sendLogin={sendLogin} setUserEmail={setUserEmail} setUserPassword={setUserPassword} setShowCodeLogin={setShowCodeLogin}/>
                }
            </div>
            : null}

        </>
        )


};


const mapStateToProps = state => {
    return {
        auth: state.auth,
        token: state.token,
        ua: state.ua,
        session: state.session
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setToken: (token) => dispatch({type: 'SET_TOKEN', payload: token}),
        setAuth: (authObj) => dispatch({type: 'SET_AUTH', payload: authObj}),
        doLogout: () => dispatch({type: 'LOGOUT'}),
        setUA: (ua) => dispatch({type: 'SET_UA', payload: ua}),
        setSession: (session) => dispatch({type: 'SET_SESSION', payload: session}),
        setSchedule: (schedule) => dispatch({type: 'SET_SCHEDULE', payload: schedule})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
