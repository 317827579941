import React from "react";
import './device.css';
import Device from "./Device";
import List from "@material-ui/core/List";


const DevicesList = ({devices, callDevice, deleteDevice}) => {
    return <div className="devicesDiv">
        <List component="nav" className="devicesList">
            {devices.map(item => <Device key={item.id} id={item.id} model={item.model} call={callDevice} deleteDevice={deleteDevice}/>)}
        </List>
    </div>
}

export default DevicesList;