import React, {useCallback, useEffect, useState} from 'react';
import DevicesList from "../../components/device/DevicesList";
import Pairing from "../../components/pairing/Pairing";
import Requests from "../../api_requests/apiLook2buy";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import StatusBar from "../../components/statusbar/StatusBar";
import LoadingCall from "../../components/stream/LoadingCall";
import VideoStream from "../../components/stream/VideoStream";
import {getStreamInfo} from "../../store/utils";
import * as act from "../../store/actions";

const RetailerAdminPage = ({session, isAuthorized, streamStats, setStreamStats}) => {
    const [devices, setDevices] = useState([]);
    const [remoteStream, setRemoteStream] = useState({
        stream: null,
        show: false
    });
    const [currentCall, setCurrentCall] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);

    const getDevices = () => {
        Requests.getGroupDevices((res) => {
            if(res.code === 200){
                setDevices(res.data);
            }
        })
    }

    const updateStats = useCallback((data) => {
        setStreamStats(getStreamInfo(data));
    }, [setStreamStats]);

    const callDeviceHandler = (id) => {
        console.log("Call device", id);
        const contact = session.getOrCreateContact(id);
        console.log("Contact online", contact.isOnline())
        const call = contact.call(null, {mediaTypeForOutgoingCall: 'AUDIO'});
        if(call){
            setShowSpinner(true);
            setCurrentCall(call);
            setCallListener(call);
        }
    }

    const setCallListener = (call) => {
        call
            // .on('localStreamAvailable', (stream) => {
            //     //stream.attachToElement(frameLocalStream);
            //     //frameLocalStream.play();
            //     localStream = stream.getData();
            //     localStream = localStream.clone();
            //     localStream.getAudioTracks().forEach(track => {
            //         console.log(track);
            //         track.stop();
            //     });
            //     playVideo(frameLocalStream, localStream);
            // })
            .on('streamAdded', (stream) => {
                console.log("Remote stream");
                setRemoteStream({...remoteStream, stream, show: true});
                //stream.attachToElement(frameRemoteStream);
                //frameRemoteStream.play();
                //playVideo(frameRemoteStream, remoteStream);
            })
            .on('userMediaError',  (e) => {
                console.log('userMediaError detected : ', e);
                console.log('userMediaError detected with error : ', e.error);
                setShowSpinner(false);
            })
            .on('hangup', (e) => {
                //console.log('hangup call '+currentCall.getId());
                console.log('hungup')
                // currentCall.hangUp();
                setShowSpinner(false);
                setRemoteStream({stream: null, show: false})
            })
            .on('statsUpdate', sts => {
                updateStats(sts.stats);
            })
    };

    const hangUpCall = () =>{
        currentCall.hangUp();
        setShowSpinner(false);
    }

    const deleteDeviceHandler = (id) => {
        Requests.deleteDevice(id, res => console.log(res))
    }

    useEffect(() => {
        if(isAuthorized){
            getDevices();
        }

    },[isAuthorized])

    return <div>
        <StatusBar />
        <Pairing />
        <Button variant="contained" color="primary" onClick={getDevices}>REFRESH DEVICES</Button>
        <DevicesList devices={devices} callDevice={callDeviceHandler} deleteDevice={deleteDeviceHandler}/>
        <LoadingCall open={showSpinner} cancelHandler={hangUpCall} />
        <VideoStream
            src={remoteStream.stream}
            show={remoteStream.show}
            muted={false}
            id="remoteStream"
            hangupCall={() => hangUpCall(currentCall)}/>
    </div>
}


const mapStateToProps = state => {
    return {
        isAuthorized: state.isAuthorized,
        session: state.session,
        streamStats: state.streamStats
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setStreamStats: (sts) => dispatch({type: act.SET_STREAM_STATS , payload: sts}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RetailerAdminPage);
