import React from "react";
import axios from 'axios';

const istAxios = axios.create({
    baseURL: 'https://api.looktobuy.com/API/v1',
    // baseURL: '/API/v1',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})

const request = async (options, disableError) => {
    istAxios.defaults.headers.common['Authorization'] = 'Bearer '+getToken();

    const onSuccess = response => {
        console.log("Request OK");
        return response.data;
    }

    const onError = error => {
        if(!disableError) {
            if (error.response) {
                // Request was made but server responded with something
                // other than 2xx
                // console.error('Status:', error.response.status);
                // console.error('Data:', error.response.data);
                // console.error('Headers:', error.response.headers);
                console.warn(`Errore generico. ${error.response.data.info}`)

            } else {
                // Something else happened while setting up the request
                // triggered the error
                console.error('Error Message:', error.message);
            }
        }
        return Promise.reject(error.response || error.message);
        // return (error.response || error.message);
    }
    return await istAxios(options)
        .then(onSuccess)
        .catch(onError);
}

const onDefaultError= error => {
    console.log("Qualcosa non va, errore durante la richiesta",error)
}

const refreshToken = (onSuccess) => {
    console.log("refresh token request")
    request({
        url: '/refreshToken',
        method: 'POST'
    })
        .then(onSuccess);
}

const login = (data, onSuccess, onError = onDefaultError) => {
    request({
        url: '/login',
        method: 'POST',
        data: {...data}

    })
        .then(onSuccess)
        .catch(onError)

}

const getDevices = (onSuccess, onError = onDefaultError) => {
    request({
        url: '/devices',
        method: 'GET'
    })
        .then(onSuccess)
        .catch(onError)
}

const postPairing = (onSuccess, onError = onDefaultError) => {
    request({
        url: '/devices/pairing',
        method: 'POST'
    })
        .then(onSuccess)
        .catch(onError)
}

const updatePairing = (token, data,onSuccess, onError = onDefaultError) => {
    request({
        url: '/devices/pairing/'+token,
        method: 'PUT',
        data: data
    })
        .then(onSuccess)
        .catch(onError)
}

const getPairing = (token, onSuccess, onError = onDefaultError) => {
    request({
        url: '/devices/pairing/'+token,
        method: 'GET'
    })
        .then(onSuccess)
        .catch(onError)
}

const saveToken = (token) => {
    if(token=== null) sessionStorage.removeItem('token')
    else sessionStorage.setItem('token', token);

}

const getToken = () => {
    return sessionStorage.getItem('token');
}

const setUserInfo = (user) => {
    sessionStorage.setItem('user_info', JSON.stringify(user));
}

const getUserInfo = () => {
    const x = JSON.parse(sessionStorage.getItem('user_info'));
    console.log(x);
    if(x === null) return {
        role: null,
        id: null,
        name: '',
        surname: ''
    }
    return x
}

const deleteDevice = (id, onSuccess, onError = onDefaultError) => {
    request({
        url: '/devices/'+id,
        method: 'DELETE'
    })
        .then(onSuccess)
        .catch(onError)
}

const getGroupMembers = (onSuccess, onError = onDefaultError) => {
    request({
        url: '/groups/members',
        method: 'GET'
    })
        .then(onSuccess)
        .catch(onError)
}

const getGroupDevices = (onSuccess, onError = onDefaultError) => {
    request({
        url: '/groups/devices',
        method: 'GET'
    })
        .then(onSuccess)
        .catch(onError)
}

const checkTokenValidity = (onSuccess, onError) => {
    request({
        url: "/tokenValidity",
        method: 'GET'
    })
        .then(onSuccess)
        .catch(onError)
}

const logout = () => {
    setUserInfo();
    saveToken(null);
}

const dummyLogin = (user_id, group_id, onSuccess, onError) => {
    request({
        url: "/dummyLogin",
        method: 'POST',
        data: {
            user_id: user_id,
            group_id: group_id
        }
    })
        .then(onSuccess)
        .catch(onError)
}


//SCHEDULES

const getAllSchedules = () => {
    return new Promise((resolve, reject) => {
        request({
            url: '/schedules',
            method: 'GET'
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    }
    )
}

const getOneSchedule = (id) => {
    return new Promise((resolve, reject) => {
            request({
                url: '/schedules/'+id,
                method: 'GET'
            })
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        }
    )
}

const addOneSchedule = (data) => {
    return new Promise((resolve, reject) => {
            request({
                url: '/schedules',
                method: 'POST',
                data: data
            })
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        }
    )
}

const deleteOneSchedule = (id) => {
    return new Promise((resolve, reject) => {
            request({
                url: '/schedules/'+id,
                method: 'DELETE'
            })
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        }
    )
}


const Requests = {
    login,
    saveToken,
    getToken,
    setUserInfo,
    getUserInfo,
    getDevices,
    postPairing,
    updatePairing,
    getPairing,
    deleteDevice,
    getGroupMembers,
    getGroupDevices,
    logout,
    checkTokenValidity,
    dummyLogin,
    getAllSchedules,
    getOneSchedule,
    addOneSchedule,
    deleteOneSchedule
}

export default Requests


