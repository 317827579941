import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    root: {
        zIndex: 1000,
        color: '#fff',
    },
    backdropContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    spinner: {
        marginTop: 20,
        marginBottom: 20
    }
})

const LoadingCall = ({open, cancelHandler}) => {
    const classes2 = useStyles();
    return (
        <Backdrop style={{zIndex: 1000, color: '#fff'}} open={open}>
            <div className={classes2.backdropContent}>
                <Typography>Chiamata in corso...</Typography>
                <CircularProgress className={classes2.spinner} color="inherit" />
                <Button color="primary" variant="outlined" onClick={cancelHandler}>Annulla</Button>
            </div>
        </Backdrop>)
}

export default LoadingCall