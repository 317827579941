import React from 'react';
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import BackupIcon from '@material-ui/icons/Backup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Rating from '@material-ui/lab/Rating';
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1
    },
    box: {
        // width: "100px",
        display: "flex",
        alignItems: "center",
        marginLeft: "5px",
        marginRight: "5px",
    },
    icon: {
        marginRight: "10px"
    },
    itemInfo: {
        display: "flex",
        flexDirection: "column",
    },
    item:{
        display: "inline-block",
        whiteSpace: 'normal',
    },
    spinner: {
        marginTop: 2,
        marginBottom: 2,
        marginRight: 10
    }
}));

const NetworkItem = ({type, kbps, raiting, showRaiting, showSpeed}) => {
    const classes = useStyles();
    const convert = (value) => {
        //input in kbps
        let unit = 'Kbps';
        let val = value;

        if(value > 1100){
            val = (value /1000).toFixed(2)
            unit = 'Mbps'
        }

        return val+' '+unit;
    }
    console.log(showRaiting)
    return (
        <div className={classes.box}>
            {showRaiting || showSpeed ? <>
                {type === 'download' ? <CloudDownloadIcon fontSize="large" className={classes.icon} /> :null}
                {type === 'upload' ? <BackupIcon fontSize="large" className={classes.icon} /> :null}
            </>
            : null}
        <div className={classes.itemInfo}>
            {showSpeed ? <span className={classes.item}>{convert(kbps)}</span> :null}
            {showRaiting ? <Rating value={parseInt(raiting.split('/')[0])} max={5} readOnly/> : null}
        </div>

    </div>
    )
}

const LoadingBox = () => {
    const classes = useStyles();
    return (<div className={classes.root}>
        <CircularProgress size={25} className={classes.spinner} color="inherit" /> Recupero informazioni di rete
    </div>)
}

const NetworkInfoBox = ({info, showRaiting, showSpeed, networkInfoLoading}) => {
    const classes = useStyles();
    console.log("info", info);

    if (networkInfoLoading) {
        return <LoadingBox />;
    } else {
        return (
            <div className={classes.root}>
                <NetworkItem type="download"
                             kbps={info.download.kbps}
                             raiting={info.download.rating}
                             showRaiting={showRaiting}
                             showSpeed={showSpeed}/>
                <NetworkItem type="upload"
                             kbps={info.upload.kbps}
                             raiting={info.upload.rating}
                             showRaiting={showRaiting}
                             showSpeed={showSpeed}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        networkInfo: state.networkInfo,
        networkInfoLoading: state.networkInfoLoading
    };
}



export default connect(mapStateToProps)(NetworkInfoBox);