import React from 'react'
import {connect} from 'react-redux'
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import NetworkInfoBox from "../NetworkInfo/NetworkInfoBox";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        maxHeight: 70
    },
    title: {
        marginRight: "20px",
        fontWeight: 'bold'
    },
    panelLeft: {
        flexGrow: 2,
        justifyContent: "flex-start",
        display: "flex",
        flexBasis: "auto"
    },
    panelCenter: {
        flexGrow: 1,
        justifyContent: "center",
        display: "flex",
        flexBasis: "auto"
    },
    panelRight: {
        flexGrow: 2,
        justifyContent: "flex-end",
        display: "flex",
        flexBasis: "auto",
        alignItems: "center"
    },
    titleStatus: {
        display: "flex",
        flexDirection: 'column',
        flexGrow:1,
        alignItems: 'start'
    }
}));

const StatusBar = ({session, auth, doLogout, history, networkInfo}) => {
    const classes = useStyles();

    const doUserLogout = () => {
        doLogout();
        // ua.unregister();
    }

    // const readRole = (role) => {
    //     switch (role) {
    //         case 1:
    //             return 'Buyer'
    //         case 2:
    //             return 'Retailer'
    //         case 999:
    //             return 'Admin'
    //         default:
    //             return role
    //     }
    // }

    return <div>
        <AppBar style={{backgroundColor: '#e41b13'}} position="static">
            <Toolbar className={classes.root}>
                <div className={classes.panelLeft}>
                    <Logo />
                    {/*<div className={classes.titleStatus}>*/}
                    {/*    <Typography className={classes.title}>Looktobuy</Typography>*/}
                    {/*    {session ? <Typography>Sessione creata</Typography> : null}*/}
                    {/*</div>*/}
                </div>
                <div className={classes.panelCenter}>
                    {networkInfo !== undefined ? <NetworkInfoBox info={networkInfo} showSpeed showRaiting/> : null}
                </div>
                <div className={classes.panelRight}>
                    <Button edge="end" onClick={doUserLogout} variant="contained" style={{backgroundColor: '#fff', color: '#000'}} >LOGOUT</Button>
                </div>
            </Toolbar>
        </AppBar>
    </div>
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        token: state.token,
        networkInfo: state.networkInfo,
        session: state.session
    };
}

const mapDispatchToProps = dispatch => {
    return {
        doLogout: () => dispatch({type: 'LOGOUT'}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
