import React, {useState} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import {makeStyles} from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import Popover from "@material-ui/core/Popover";


const bitsToBytes = (bits) => {
    return ((bits/8)/1000).toFixed(3);
}


const useStyles = makeStyles({
    stats: {
        color: "#424242",
        minWidth: "250px",
    },
    btnInfo: {
        color: '#ffffff'
    }
})


const Statistics = ({stats}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    console.log("statistics", stats);


    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    }

    return (
        <>
            <IconButton onClick={handleOpen} style={{ color: 'white', fontSize: 30 }}>
                <InfoIcon />
            </IconButton>
            <Popover
                id="filters_popover"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                open={open}
            >
                <div className={classes.stats}>
                    <ul>
                        <span>Received Audio</span>
                        <li>packetsLost: {_.get(stats, ['ar_packetLost'])}</li>
                        <li>Jitter: {_.get(stats, ['ar_jitter'])}</li>
                        <li>KBytes/s: {bitsToBytes(_.get(stats, ['ar_bitsReceivedPerSecond']))}</li>
                    </ul>
                    <ul>
                        <span>Received Video</span>
                        <li>packetsLost: {_.get(stats, ['vr_packetLost'])}</li>
                        <li>KBytes/s: {bitsToBytes(_.get(stats, ['vr_bitsReceivedPerSecond']))}</li>
                        <li>Frame/s: {_.get(stats,['vr_framePerSecond'])}</li>
                        <li>Resolution: {_.get(stats, ['vr_resolution'])}</li>
                    </ul>
                    <ul>
                        <span>Sent Audio</span>
                        <li>KBytes/s: {bitsToBytes(_.get(stats, ['as_bitsSentPerSecond']))}</li>
                    </ul>
                </div>
            </Popover>

        </>
    )
}

const mapStateToProps = state => {
    return {
        // stats: state.streamStats.ts_update,
        // streamStats: state.streamStats,
        stats: state.streamStats
    };
}

export default connect(mapStateToProps)(Statistics);