import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CallIcon from '@material-ui/icons/Call';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: "#fdb301",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        marginBottom: 3
    },
}));

const RetailerItem = ({id, name, surname, call}) => {
    const classes = useStyles();

    return (
        <ListItem className={classes.root}>
            <ListItemText>{name} {surname}</ListItemText>
            <IconButton edge="end" aria-label="call" onClick={() => call(id)}>
                <CallIcon/>
            </IconButton>
        </ListItem>
    )
}

export default RetailerItem;