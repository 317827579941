import React, {useState, useEffect, useCallback} from "react";
import QRCode from "react-qr-code";
import Requests from "../../api_requests/apiLook2buy";
import {connect} from 'react-redux'
import * as act from '../../store/actions';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
const QRCodeDialog = ({onClose, open, value, onCancel}) => {

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>QR Code token</DialogTitle>
            <DialogContent>
                <DialogContentText>{value}</DialogContentText>
                {value ? <QRCode value={value} /> : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}


const Pairing = ({xInterval, setXInterval, inPairing, setTokenCode, setInPairing, tokenCode}) => {
    const [dialogShow, setDialogShow] = useState(false);
    const [currInterval, setCurrInterval] = useState(null);

    const sendPostPairing = () => {
        if(!inPairing) {
            Requests.postPairing(res => {
                if (res.code === 201) {
                    console.log(res);
                    setTokenCode(res.data.tokenCode)
                    setInPairing(true);
                    //start setInterval and setTimeout
                    setXInterval(true);
                    //startTimers(props);
                    showQRCodeDialog();
                }
            }, res => alert(res.data.info))
        }
        else {
            console.log('utente già in pairing');
            showQRCodeDialog();
        }
    }

    const showQRCodeDialog = () => {
        setDialogShow(true);
        if(inPairing) setXInterval(true);
    }

    const handleCloseDialog = useCallback(() => {
        clearInterval(currInterval);
        setDialogShow(false);
    }, [currInterval, setDialogShow]);

    const cbAbortPairing = useCallback(() => {
        Requests.updatePairing(tokenCode, {state: -30}, res => console.log(res));
        setInPairing(false);
        setTokenCode(null);
        handleCloseDialog();
    }, [setInPairing, handleCloseDialog, setTokenCode, tokenCode]);

    const handlePollingResponse = useCallback((res) => {
        console.log(res);
        if (res.data.state !== 10) {
            console.log("Pairing completato: "+res.data.state);
            setInPairing(false);
            handleCloseDialog();

        }
    }, [handleCloseDialog, setInPairing]);


    const pollingState = useCallback(() => {
        console.log('tokenCode', tokenCode);
        if(tokenCode !== null) {
            Requests.getPairing(tokenCode,handlePollingResponse);
        }
    }, [tokenCode, handlePollingResponse]);


    useEffect(() => {
        if(xInterval) {
            console.log(currInterval);
            if(currInterval) clearInterval(currInterval);
            console.log('start interval');
            const ci = setInterval(() => {
                console.log("handler..");
                pollingState();
            }, 2000);
            setCurrInterval(ci);
            setXInterval(false);
        }
        if(!xInterval && !inPairing){
            if(currInterval) clearInterval(currInterval);
        }
        return () => clearInterval(currInterval);
    }, [currInterval, pollingState, setXInterval, xInterval, setCurrInterval, inPairing]);




    return <div>
        <Button variant="contained" color="secondary" onClick={sendPostPairing}>PAIR SMARTGLASS</Button>
        {inPairing ? <span>In pairing - token: {tokenCode}</span>: null}
        <QRCodeDialog open={dialogShow} onClose={handleCloseDialog} value={tokenCode} onCancel={cbAbortPairing}/>
    </div>
}

const mapStateToProps = state => {
    return {
        tokenCode: state.tokenCode,
        inPairing: state.inPairing,
        xInterval: state.xInterval
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setTokenCode: (token) => dispatch({type: act.SET_TOKEN_CODE, payload: token}),
        setInPairing: (st) => dispatch({type: act.SET_IN_PAIRING, payload: st}),
        setXInterval: (st) => dispatch({type: act.SET_XINTERVAL, payload: st})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pairing);
