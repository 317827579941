export const SET_AUTH = 'SET_AUTH';
export const SET_TOKEN = 'SET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN_CODE = 'SET_TOKEN_CODE'
export const SET_IN_PAIRING = 'SET_IN_PAIRING'
export const SET_XINTERVAL = 'SET_XINTERVAL';
export const SET_SESSION = 'SET_SESSION';
export const SET_UA = 'SET_UA';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_NETWORK_INFO = 'SET_NETWORK_INFO';
export const SET_NETWORK_LOADING = 'SET_NETWORK_LOADING';
export const SET_STREAM_STATS = 'SET_STREAM_STATS';
export const SET_VIDEO_FILTERS = 'SET_VIDEO_FILTERS';
export const SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED';
export const SET_ADMIN_SCHEDULES = 'SET_ADMIN_SCHEDULES';
export const SET_SCHEDULE = 'SET_SCHEDULE';
