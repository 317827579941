import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        margin: 0,
        padding: 0,
        // alignItems: 'center',
        // alignContent: 'center',
        display: 'flex',
        marginRight: 10,
        marginLeft: 10
    },
    logo: {
        height: "80%",
        maxHeight: 50,
        width: 295
    }

}));

const Logo = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img
            src="/assets/agilab_logo.svg"
            alt="Agilab"
            className={classes.logo}
            />
        </div>
    )
}

export default Logo;