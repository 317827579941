import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import FinalApp from './App';
import * as serviceWorker from './serviceWorker';

//
// import {createStore} from 'redux';
// import {Provider} from 'react-redux';
//
// import reducer from "./store/reducer";
//
// const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
//
// ReactDOM.render(
//       <Provider store={store}>
//           <App />
//       </Provider>,
//   document.getElementById('root')
// );

ReactDOM.render(
    <FinalApp />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
