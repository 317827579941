import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CallIcon from '@material-ui/icons/Call';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
        backgroundColor: '#fdb301',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        marginBottom: 3
    }
});



const Device = ({id, model, call, deleteDevice}) => {
    const styles = useStyles();

    return <ListItem className={styles.root}>
        <ListItemText>{id} - {model}</ListItemText>
        <IconButton edge="end" aria-label="call" onClick={() => call(id)}>
            <CallIcon/>
        </IconButton>
        <IconButton edge="end" onClick={() => deleteDevice(id)}>
            <DeleteIcon/>
        </IconButton>
    </ListItem>
}

export default Device;