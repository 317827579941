import React, {useState} from 'react'
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import {IconButton} from "@material-ui/core";

const MicToggle = ({onMicEnable, onMicDisable, className}) => {
    // state == true ==>  mic abilitato
    const [state, setState] = useState(true)

    const toggleState = () => {
        let st = !state;
        setState(st);
        if(st) onMicEnable();
        else onMicDisable()
    }

    const style = {
        on: {
            color: 'white',
            fontSize: 40
        },
        off: {
            color: 'white',
            fontSize: 40
        }}
    return (
        <IconButton onClick={toggleState} className={className}>
            {state? <MicIcon style={style.on} /> : <MicOffIcon style={style.off}/>}
        </IconButton>
    )
}


export default MicToggle;