import React, {useEffect, useState, useCallback} from 'react';
import {connect} from 'react-redux'
import Requests from "../../api_requests/apiLook2buy";
import RetailerList from "../../components/retailer/RetailerList";
import VideoStream from "../../components/stream/VideoStream";
import StatusBar from "../../components/statusbar/StatusBar";
import * as act from '../../store/actions';
import {makeStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import {getStreamInfo} from "../../store/utils";
import LoadingCall from "../../components/stream/LoadingCall";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    title: {
        margin: 5,
        fontWeight: 'bold',
        fontSize: 20
    },
    callNowButton: {
        maxWidth: 300,
        marginTop: 150,
        zIndex: 1,
        cursor: 'pointer'
    }
});


const BuyerHomePage = ({session, streamStats, setStreamStats, doLogout}) => {
    const classes = useStyles();
    const [enableCall, setEnableCall] = useState(true);
    const [members, setMembers] = useState({});
    const [devices, setDevices] = useState({
        all: [],
        online: []
    });

    const [showSpinner, setShowSpinner] = useState(false);
    const [remoteStream, setRemoteStream] = useState({
        stream: null,
        show: false
    });
    const [localStream, setLocalStream] = useState(null);
    const [currentCall, setCurrentCall] = useState(1);

    const updateStats = useCallback((data) => {
        setStreamStats(getStreamInfo(data));
    }, [setStreamStats]);

    const logoutOnHangup = () => {
        setEnableCall(false)
        setTimeout(doLogout, 1000)
    }

    const setCallListener = (call) => {
        call
            .on('localStreamAvailable', stream => {
                setLocalStream(stream);
            })
            .on('streamAdded', (stream) => {
                console.log("Remote stream");
                setRemoteStream({...remoteStream, stream, show: true});
                setShowSpinner(false);
            })
            .on('userMediaError',  (e) => {
                console.log('userMediaError detected : ', e);
                console.log('userMediaError detected with error : ', e.error);
                setShowSpinner(false);
            })
            .on('hangup', (e) => {
                //console.log('hangup call '+currentCall.getId());
                console.log('hungup')
                // currentCall.hangUp();
                setRemoteStream({stream: null, show: false})
                setShowSpinner(false);
                logoutOnHangup()
            })
            .on('statsUpdate', sts => {
                updateStats(sts.stats);
            })
    };

    const hangUpCall = () =>{
        currentCall.hangUp();
        setShowSpinner(false);
        logoutOnHangup()
    }

    const getGroupMembers = useCallback( () => {
        Requests.getGroupMembers( res => {
            console.log("get group members", res.data);
            let membs = {};
            console.log("callb group members",res);
            res.data.forEach(elem => {
                console.log("member", elem);
                membs[elem.id] = elem;
            });
            setMembers(membs);
        })
    }, [])

    //filtra la lista di device per lo stato online in apiRTC
    const getOnlineDevice = useCallback((devices) => {
        return devices.filter(device => {
            const contact = session.getOrCreateContact(device.id);
            if(contact.isOnline()){
                device.contact = contact;
                return true;
            }
            return false;
        })
    }, [session]);

    const getGroupDevices = useCallback(() => {
        Requests.getGroupDevices( res => {
            console.log("get group devices", res.data)
            let obj = {
                all: res.data,
                online: getOnlineDevice(res.data)
            }
            setDevices({...obj});
            // console.log("online devices",getOnlineDevice(devices));
        })
    }, [getOnlineDevice]);



    const memberCallHandler = (id) => {
        console.log("retailer call handler", id);
        console.log();
        //viene chiamato il primo device disponibile
        devices.online.some(device => {
            const call = device.contact.call(null, {mediaTypeForOutgoingCall: 'VIDEO'});
            if(call){
                setCurrentCall(call);
                setCallListener(call);
                setShowSpinner(true);
                return true;
            }
            return false

        })
    }


    const callNow = () => {
        console.log("Avvio chiamata")
        if(!enableCall) return;
        devices.online.some(device => {
            const call = device.contact.call(null, {mediaTypeForOutgoingCall: 'VIDEO'});
            if(call){
                setCurrentCall(call);
                setCallListener(call);
                setShowSpinner(true);
                console.log(`Chiamata al device ${device.id} in corso..`);
                return true;
            }
            return false

        })
    }

    useEffect(() => {
        if(session !== null && session !== undefined && Object.keys(members).length === 0 && devices.all.length === 0) {
            console.log("############## use effect")
            getGroupMembers();
            getGroupDevices();
        }
    },[session, getGroupDevices, getGroupMembers, devices, members])



    return <div>
        <StatusBar />
        <LoadingCall open={showSpinner} cancelHandler={hangUpCall} />

        {/*<RetailerList elements={Object.keys(members).map(key => members[key] )} callHandler={memberCallHandler} />*/}
        <VideoStream
            src={remoteStream.stream}
            localStream={localStream}
            show={remoteStream.show}
            // show={true}
            muted={false}
            id="remoteStream"
            hangupCall={() => hangUpCall(currentCall)}
            // stats={streamStats}
        />
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>

            <div style={{display: 'flex', flex: 1,flexDirection: 'column', padding: 10}}>
                <Typography variant="h5" style={{marginBottom: 20}}>Benvenuto in Agilab</Typography>
                <Typography style={{maxWidth: 500}} variant="body1">Un ampio ecosistema di tecnologie innovative per l'industria.</Typography>
                <Typography style={{maxWidth: 500}} variant="body1">Osserva il processo produttivo e immagina di sviluppare quelle soluzioni su misura della tua azienda: dall'Additive Manufacturing al CNC, dalla robotica fissa e mobile al Middleware di gestione della produzione, dalla visione artificiale all'Energy Efficiency.</Typography>
                <Typography style={{maxWidth: 500, marginTop: 10, marginBottom: 10}} variant="body1"><strong>Tutto il meglio della Smart Industry applicato in scala.</strong></Typography>
                <Typography style={{maxWidth: 500}} variant="body1">Lasciati accompagnare in una visita del nostro laboratorio robocentrico: potrai vedere Agilab in funzione, chiederci informazioni dettagliate, e approfondire gli aspetti che più cattureranno la tua attenzione.</Typography>

                <Typography style={{maxWidth: 500, marginTop: 20}} variant="body2">*Assicurati di consentire l'accesso a webcam e microfono</Typography>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', flex: 1}}>
                <img
                    src="/assets/agilab_call_now_button.svg"
                    alt="CallNow"
                    className={classes.callNowButton}
                    onClick={callNow}
                />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignContent:'flex-end', flex: 1}}>
                <Typography variant="h6" style={{marginRight: 20, marginTop: 20}} align={"right"}>visita il sito</Typography>
                <Button style={{marginRight: 20, marginTop: 10, backgroundColor: '#e41b13', color: 'white', fontSize: '1em', width: 100}}
                        href="https://agilab.it"
                        target="_blank"
                        variant={"contained"}
                >AGILAB.IT</Button>
            </div>
        </div>
    </div>
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        session: state.session,
        ua: state.ua,
        streamStats: state.streamStats
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setStreamStats: (sts) => dispatch({type: act.SET_STREAM_STATS , payload: sts}),
        doLogout: () => dispatch({type: act.LOGOUT})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerHomePage);
