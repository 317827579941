import React, {useCallback, useEffect, useState} from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import {connect} from "react-redux";


import * as act from './store/actions';
import BuyerHomePage from "./pages/buyer/BuyerHomePage";
import RetailerAdminPage from "./pages/admin/RetailerAdminPage";
import LoginPage from "./pages/login/LoginPage";
import AdminPage from "./pages/admin/AdminPage";

import {createStore} from 'redux';
import {Provider} from 'react-redux';

import reducer from "./store/reducer";
import Requests from "./api_requests/apiLook2buy";

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


const RoleRoute = ({children, auth, role, ...rest}) => {
    return (
        <Route
            {...rest}
            render={({location}) =>
                auth === role ? (children) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    )
}


const HomeRoute = (props) => {
    console.log("query params: ",window.location.search)
    if (!props.isAuth) return (<Redirect to={"/login"+window.location.search}/>);
    else {
        if (props.role === 1) return (<Redirect to="/buyer"/>)
        else return (<Redirect to="/retailer"/>)
    }
}

function App({auth, token, setUA, ua, session, setSession, setNetworkInfo, setNetworkLoading,isAuthorized, setIsAuthorized}) {

    const createSession = useCallback((lua) => {
        const registerInfo = {
            id: auth.id,
            token: token
        }
        if(lua && session === null && auth.id !== null && token) {
            console.log("registrazione ua in apirtc");
            if (lua.isRegistered()) {
                const ss = lua.getCurrentSession()
                console.log("get session from ua")
                if(ss !== null){
                    setSession(ss);
                }
            } else {
                console.log("register session")
                lua.register(registerInfo)
                    .then(ss => {
                        setSession(ss);
                        console.log("Set session");
                    })
                    .catch(error => {
                        console.log("Impossibile creare la sessione", error);
                    })
            }
        }
    }, [auth, token, setSession, session]);

    useEffect(()=> {
        console.log("token validity:",token)

        if(token) {
            Requests.checkTokenValidity(
                () => {
                    console.log('Autorizzato')
                    setIsAuthorized(true);
                },
                (error) => {
                    console.log("Non autorizzato")
                    setIsAuthorized(false);
                });
        }
        else setIsAuthorized(false);

    }, [token]);

    useEffect(() => {
        if(isAuthorized && ua === null) {
            console.log('apzkey:'+auth.apiKey)
            // eslint-disable-next-line no-undef
            const _ua = new apiRTC.UserAgent({
                uri: 'apzkey:'+auth.apiKey
            });
            setUA(_ua);
            console.log("init app create session")
            _ua.enableCallStatsMonitoring(true, 2000);
            createSession(_ua);
            setNetworkLoading(true);
            _ua.fetchNetworkInformation_v2()
                .then(res => {
                    setNetworkLoading(false);
                    setNetworkInfo(res);
                })
        }

    }, [ua, auth, setUA, session, setSession, createSession, setNetworkInfo, isAuthorized]);

  return (
      <Router>
        <div className="App">

            <Switch>
                <Route path="/login" component={LoginPage} />
                <RoleRoute exact path="/guest" auth={auth.role} role={1}>
                    <BuyerHomePage />
                </RoleRoute>
                <RoleRoute exact path="/retailer" auth={auth.role} role={2}>
                    <RetailerAdminPage />
                </RoleRoute>
                <RoleRoute exact path="/admin" auth={auth.role} role={999}>
                    <AdminPage />
                </RoleRoute>
                <Route path="/" render={(props) => <HomeRoute isAuth={auth.id!== null} role={auth.role}/>} />
            </Switch>
        </div>
      </Router>
  );
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        token: state.token,
        ua: state.ua,
        session: state.session,
        isAuthorized: state.isAuthorized
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setToken: (token) => dispatch({type: act.SET_TOKEN, payload: token}),
        setAuth: (authObj) => dispatch({type: act.SET_AUTH, payload: authObj}),
        doLogout: () => dispatch({type: act.LOGOUT}),
        setUA: (ua) => dispatch({type: act.SET_UA, payload: ua}),
        setSession: (session) => dispatch({type: act.SET_SESSION, payload: session}),
        setNetworkInfo: (ni) => dispatch({type: act.SET_NETWORK_INFO, payload: ni}),
        setIsAuthorized: (st) => dispatch({type: act.SET_IS_AUTHORIZED, payload: st}),
        setNetworkLoading: (st) => dispatch({type: act.SET_NETWORK_LOADING, payload: st})
    }
}

const ExportApp = connect(mapStateToProps, mapDispatchToProps)(App);

const FinalApp = () => {
    return(
        <Provider store={store}>
            <ExportApp />
        </Provider>
    )
}

export default FinalApp;
